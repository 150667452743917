import Contacts from "@material-ui/icons/Contacts";
import React, { useState, useEffect } from "react";
import {
  BooleanInput,
  FormDataConsumer,
  FormTab,
  TextInput,
  number,
  required,
  regex,
  useTranslate,
} from "react-admin";
import { Field, useForm } from "react-final-form";
import { Box, Grid, Button, TextField } from "@mui/material";
import ListIcon from '@material-ui/icons/List';
import { styled } from '@mui/material/styles';
import { FieldLabel, GroupContent } from "./SettingsStyle";
import { AutoDeleteMessages } from "../common/AutoDeleteMessages";
import { RotationConfig } from "../common/RotationConfig";
import ListIPAddress from "./SecuritySettings/ListIPAddress";
import { getHoursBySeconds, getSecondsByHours } from "../../helper/utils";
import { getServerConfig } from "../../helper/serverConfig";

// noinspection SpellCheckingInspection
export const SecuritySettingKeys = {
  ENABLE_REQUIRED_TWO_FACTOR: "ENABLE_REQUIRED_TWO_FACTOR",
  ENABLE_REQUIRED_YUBIKEY: "ENABLE_REQUIRED_YUBIKEY",
  TTL_KEY_SHARE: "TTL_KEY_SHARE",
  ALLOW_TO_EXPORT_CHAT: "ALLOW_TO_EXPORT_CHAT",
  FORCE_UPDATE_APP_SYNCHRONIZATION: "FORCE_UPDATE_APP_SYNCHRONIZATION",
  ENABLE_FORCE_UPDATE_APP: "ENABLE_FORCE_UPDATE_APP",
  ALLOW_CONFIG_WHITELISTED_IPS: "ALLOW_CONFIG_WHITELISTED_IPS",
  ALERT_NEW_LOGIN: "ALERT_NEW_LOGIN",
  ENABLE_LOG_IP_LOCATION: "ENABLE_LOG_IP_LOCATION",
  USE_FREEIPAPI_COM: "USE_FREEIPAPI_COM",
  USE_IPINFO_IO: "USE_IPINFO_IO",
  USE_IP2LOCATION_IO: "USE_IP2LOCATION_IO",
  USE_IP2LOCATION_DB: "USE_IP2LOCATION_DB",
  PLATFORM_NUMBER_OF_RETRY_CHECKS: "PLATFORM_NUMBER_OF_RETRY_CHECKS",
  PLATFORM_PERIOD_RETRY_CHECKS: "PLATFORM_PERIOD_RETRY_CHECKS",
  DEFAULT_RETENTION: "DEFAULT_RETENTION",
  LOGIN_FAILED_IP_ENABLED: "LOGIN_FAILED_IP_ENABLED",
  LOGIN_FAILED_IP_MAX_ATTEMPT: "LOGIN_FAILED_IP_MAX_ATTEMPT",
  LOGIN_FAILED_IP_ATTEMPT_PERIOD: "LOGIN_FAILED_IP_ATTEMPT_PERIOD",
  DEFAULT_ROTATION_PERIOD_MESSAGES: "DEFAULT_ROTATION_PERIOD_MESSAGES",
};

const initValidations = {
  [SecuritySettingKeys.LOGIN_FAILED_IP_MAX_ATTEMPT]: '',
  [SecuritySettingKeys.LOGIN_FAILED_IP_ATTEMPT_PERIOD]: '',
};

export const SecuritySettings = props => {
  const translate = useTranslate();
  const form = useForm();
  const [showBlacklistIps, setShowBlacklistIps] = useState(false);
  const [formViewSettings, setFormViewSettings] = useState({});
  const [validations, setValidations] = useState(initValidations);

  const requiredIPInfoTokenOptions = (value, form) => {
    return form.USE_IPINFO_IO && !value
      ? "resources.settings.validations.required_ip_info_io_token"
      : undefined;
  };

  const onMaxLifetimeChange = (value) => {
    form.change(SecuritySettingKeys.DEFAULT_RETENTION, value);
  }

  const onRotationChange = (value) => {
    form.change(SecuritySettingKeys.DEFAULT_ROTATION_PERIOD_MESSAGES, value);
  }

  // const requiredIP2LocationApiKeyOptions = (value, form) => {
  //   return form.USE_IP2LOCATION_IO && !value
  //     ? "resources.settings.validations.required_ip2location_io_api_key"
  //     : undefined;
  // };

  useEffect(() => {
    let mapKeys = {};
    for (const [key, value] of Object.entries(form.getState().values)) {
      if (key === SecuritySettingKeys.LOGIN_FAILED_IP_MAX_ATTEMPT) {
        mapKeys = {
          ...mapKeys,
          [key]: value,
        }
        continue;
      }
      if (key === SecuritySettingKeys.LOGIN_FAILED_IP_ATTEMPT_PERIOD) {
        mapKeys = {
          ...mapKeys,
          [key]: getHoursBySeconds(value),
        }
        continue;
      }
    }
    setFormViewSettings({...mapKeys});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openBlacklistIps = () => {
    setShowBlacklistIps(true);
  };

  const onBackToSettings = () => {
    setShowBlacklistIps(false);
  };

  const handleMessageErr = (value, field) => {
    if (
      !value?.length &&
      (field === SecuritySettingKeys.LOGIN_FAILED_IP_MAX_ATTEMPT ||
      field === SecuritySettingKeys.LOGIN_FAILED_IP_ATTEMPT_PERIOD)
    ) {
        return `resources.settings.validations.required`;
    }
    if (
      !(/^(?:[1-9]|[1-9][0-9]|100)$/.test(value)) &&
      field === SecuritySettingKeys.LOGIN_FAILED_IP_MAX_ATTEMPT
    ) {
        return `resources.settings.validations.range_login_failed_ip_max_attempt`;
    }
    const minPeriod = getServerConfig("brand") === 'CTalk' ? 0.0166666667 : 24; // 1min or 24h
    if (
      value < minPeriod &&
      field === SecuritySettingKeys.LOGIN_FAILED_IP_ATTEMPT_PERIOD
    ) {
        return `resources.settings.validations.min_login_failed_ip_attempt_period`;
    }
    const maxPeriod = 168; // 7d
    if (
      value > maxPeriod &&
      field === SecuritySettingKeys.LOGIN_FAILED_IP_ATTEMPT_PERIOD
    ) {
        return `resources.settings.validations.max_login_failed_ip_attempt_period`;
    }
    return '';
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    const isNumberPositive = /^\d*\.?\d+$/.test(value);
    if (!isNumberPositive) {
      onValueChange(name, formViewSettings[name]);
    }
    onValueChange(name, value);
    setValidations({
      ...validations,
      [name]: handleMessageErr(value, name),
    });
    props.onChange(!!handleMessageErr(value, name));
  };

  const onValueChange = (name, valueInput) => {
    let value = valueInput?.replace(/^0+(?=\d)|(?<=\d)0+(?=\.)/, ''); // Removes leading zeros
    setFormViewSettings({
      ...formViewSettings,
      [name]: value,
    });
    if (name === SecuritySettingKeys.LOGIN_FAILED_IP_ATTEMPT_PERIOD) {
      value = getSecondsByHours(value);
    }
    form.change(name, value);
  };


  if (showBlacklistIps) {
    return (<FormTab {...props} label="resources.settings.tabs.security" icon={<Contacts />}>
        <ListIPAddress
            onBackToSettings={onBackToSettings}
        />
    </FormTab>);
  }

  return (
    <FormTab
      {...props}
      label="resources.settings.tabs.security"
      icon={<Contacts />}
    >
      <FormDataConsumer>
        {({ formData, ..._ }) => {
          return <Grid container width="100%" spacing={2}>
            <Grid width="40%" margin="15px">
              <FieldLabel>
                {translate("resources.settings.fields.login_failed_ip_label")}
              </FieldLabel>
              <GroupContent>
                <BooleanInput
                  source={SecuritySettingKeys.LOGIN_FAILED_IP_ENABLED}
                  label="resources.settings.fields.login_failed_ip_enable"
                  fullWidth
                />
                <StyledTextField
                  id="login_failed_ip_max_attempt"
                  label={translate("resources.settings.fields.login_failed_ip_max_attempt")}
                  name={SecuritySettingKeys.LOGIN_FAILED_IP_MAX_ATTEMPT}
                  value={formViewSettings[SecuritySettingKeys.LOGIN_FAILED_IP_MAX_ATTEMPT]}
                  onChange={handleChange}
                  onClick={handleChange}
                  variant="outlined"
                  size="small"
                  required
                  fullWidth
                  type="number"
                  error={validations[SecuritySettingKeys.LOGIN_FAILED_IP_MAX_ATTEMPT]}
                  helperText={
                    validations[SecuritySettingKeys.LOGIN_FAILED_IP_MAX_ATTEMPT] ?
                      translate(validations[SecuritySettingKeys.LOGIN_FAILED_IP_MAX_ATTEMPT]) :
                      translate("resources.settings.fields.login_failed_ip_max_attempt_helper")
                  }
                />
                <StyledTextField
                  id="login_failed_ip_attempt_period"
                  label={translate("resources.settings.fields.login_failed_ip_attempt_period")}
                  name={SecuritySettingKeys.LOGIN_FAILED_IP_ATTEMPT_PERIOD}
                  value={formViewSettings[SecuritySettingKeys.LOGIN_FAILED_IP_ATTEMPT_PERIOD]}
                  onChange={handleChange}
                  onClick={handleChange}
                  variant="outlined"
                  size="small"
                  required
                  fullWidth
                  type="number"
                  error={validations[SecuritySettingKeys.LOGIN_FAILED_IP_ATTEMPT_PERIOD]}
                  helperText={
                    validations[SecuritySettingKeys.LOGIN_FAILED_IP_ATTEMPT_PERIOD] ?
                      translate(validations[SecuritySettingKeys.LOGIN_FAILED_IP_ATTEMPT_PERIOD]) :
                      translate("resources.settings.fields.login_failed_ip_attempt_period_helper")
                  }
                />
              </GroupContent>
              <Field name={SecuritySettingKeys.DEFAULT_RETENTION}>{()=>null}</Field>
              <FieldLabel>
                {translate("resources.settings.fields.force_auto_update_synchronization_label")}
              </FieldLabel>
              <GroupContent>
                <BooleanInput
                  source={SecuritySettingKeys.ENABLE_FORCE_UPDATE_APP}
                  label="resources.settings.fields.enable_force_update_app"
                  fullWidth
                />
                <GroupContent>
                  <FormDataConsumer>
                    {
                      ({ formData }) =>
                        formData.ENABLE_FORCE_UPDATE_APP && <BooleanInput
                          source={SecuritySettingKeys.FORCE_UPDATE_APP_SYNCHRONIZATION}
                          label="resources.settings.fields.force_auto_update_synchronization"
                          fullWidth
                        />
                    }
                  </FormDataConsumer>
                </GroupContent>
              </GroupContent>
              <FieldLabel>
                {translate("resources.settings.fields.two_factor_label")}
              </FieldLabel>
              <GroupContent>
                <BooleanInput
                  source={SecuritySettingKeys.ENABLE_REQUIRED_TWO_FACTOR}
                  label="resources.settings.fields.two_factor_enable"
                  fullWidth
                />
                <BooleanInput
                  source={SecuritySettingKeys.ENABLE_REQUIRED_YUBIKEY}
                  label="resources.settings.fields.yubikey_enable"
                  fullWidth
                />
              </GroupContent>
              <FieldLabel>
                {translate("resources.settings.fields.room_label")}
              </FieldLabel>
              <GroupContent>
                <BooleanInput
                  source={SecuritySettingKeys.ALLOW_TO_EXPORT_CHAT}
                  label="resources.settings.fields.allow_to_export_chat"
                  fullWidth
                />
                <BooleanInput
                  source={SecuritySettingKeys.ALLOW_CONFIG_WHITELISTED_IPS}
                  label="resources.settings.fields.allow_config_whitelisted_ips"
                  fullWidth
                />
              </GroupContent>
              <FieldLabel>
                {translate("resources.settings.fields.user_label")}
              </FieldLabel>
              <GroupContent>
                <TextInput
                  source={SecuritySettingKeys.TTL_KEY_SHARE}
                  label="resources.settings.fields.ttl_key_share"
                  variant="outlined"
                  type="number"
                  fullWidth
                  validate={[
                    required(),
                    number(),
                    regex(/^[1-9]\d{0,5}$/, "resources.settings.validations.regex_integer"),
                  ]}
                />
                <Box mb={2}>
                  <BooleanInput
                    source={SecuritySettingKeys.ALERT_NEW_LOGIN}
                    label="resources.settings.fields.alert_new_login"
                    helperText="resources.settings.fields.helper_alert_new_login"
                    fullWidth
                  />
                </Box>
                <BooleanInput
                  source={SecuritySettingKeys.ENABLE_LOG_IP_LOCATION}
                  label="resources.settings.fields.enable_log_ip_location"
                  fullWidth
                />
                <GroupContent>
                  <FormDataConsumer>
                    {
                      ({ formData }) =>
                        formData.ENABLE_LOG_IP_LOCATION && (
                          <>
                            <Box mb={2} fontSize={15} lineHeight={1.6} color="text.secondary">{
                              translate('resources.settings.fields.enable_log_ip_location_helper_text')
                            }</Box>
                            <Box my={2}>
                              <BooleanInput
                                source={SecuritySettingKeys.USE_IPINFO_IO}
                                label="resources.settings.fields.use_ipinfo_io"
                                helperText="resources.settings.fields.helper_use_ipinfo_io"
                                fullWidth
                              />
                              <FormDataConsumer>
                                {
                                  ({ formData }) =>
                                    formData.USE_IPINFO_IO && (
                                      <Box my={1}>
                                        <TextInput
                                          source="IP_INFO_IO_TOKEN"
                                          label="resources.settings.fields.ipinfo_io_token"
                                          variant="outlined"
                                          fullWidth
                                          helperText="resources.settings.fields.helper_ipinfo_io_token"
                                          validate={requiredIPInfoTokenOptions}
                                        />
                                      </Box>
                                    )
                                }
                              </FormDataConsumer>
                            </Box>
                            { /* <Box my={2}>
                              <BooleanInput
                                source={SecuritySettingKeys.USE_IP2LOCATION_IO}
                                label="resources.settings.fields.use_ip2location_io"
                                helperText="resources.settings.fields.helper_use_ip2location_io"
                                fullWidth
                              />
                              <FormDataConsumer>
                                {
                                  ({ formData }) =>
                                    formData.USE_IP2LOCATION_IO && (
                                      <Box my={1}>
                                        <TextInput
                                          source="IP2LOCATION_IO_API_KEY"
                                          label="resources.settings.fields.ip2location_io_api_key"
                                          variant="outlined"
                                          fullWidth
                                          helperText="resources.settings.fields.helper_ip2location_io_api_key"
                                          validate={requiredIP2LocationApiKeyOptions}
                                        />
                                      </Box>
                                    )
                                }
                              </FormDataConsumer>
                            </Box>
                            <BooleanInput
                              source={SecuritySettingKeys.USE_FREEIPAPI_COM}
                              label="resources.settings.fields.use_freeipapi_com"
                              helperText="resources.settings.fields.helper_use_freeipapi_com"
                              fullWidth
                            /> */ }
                            <BooleanInput
                              source={SecuritySettingKeys.USE_IP2LOCATION_DB}
                              label="resources.settings.fields.use_ip2location_db"
                              helperText="resources.settings.fields.helper_use_ip2location_db"
                              fullWidth
                            />
                            <BooleanInput
                              source={''}
                              readonly={true}
                              disabled={true}
                              label="resources.settings.fields.use_iplocation_net"
                              fullWidth
                              helperText={false}
                              validate={null}
                            />
                            <p className="MuiFormHelperText-root">{
                              translate('resources.settings.fields.helper_use_iplocation_net')
                            }</p>
                          </>
                        )
                    }
                  </FormDataConsumer>
                </GroupContent>
              </GroupContent>
              <FieldLabel>
                {translate("resources.settings.fields.auto_delete")}
              </FieldLabel>
              <GroupContent>
                <AutoDeleteMessages
                  translate={translate}
                  onMaxLifetimeChange={onMaxLifetimeChange}
                  maxLifetime={formData.DEFAULT_RETENTION}
                  switchLabel={translate("resources.settings.fields.active_auto_delete")}
                />
              </GroupContent>
              <FieldLabel>
                {translate("resources.settings.fields.platform_label")}:
              </FieldLabel>
              <GroupContent>
                <TextInput
                  source={SecuritySettingKeys.PLATFORM_NUMBER_OF_RETRY_CHECKS}
                  label="resources.settings.fields.platform_number_of_retry_checks"
                  variant="outlined"
                  type="number"
                  fullWidth
                  validate={[
                    required(),
                    number(),
                    regex(/^[1-9]\d{0,5}$/, "resources.settings.validations.regex_integer"),
                  ]}
                />
                <TextInput
                  source={SecuritySettingKeys.PLATFORM_PERIOD_RETRY_CHECKS}
                  label="resources.settings.fields.platform_period_retry_checks"
                  variant="outlined"
                  type="number"
                  fullWidth
                  validate={[
                    required(),
                    number(),
                    regex(/^[1-9]\d{0,5}$/, "resources.settings.validations.regex_integer"),
                  ]}
                />
              </GroupContent>
              <FieldLabel>
                {translate("resources.settings.fields.encryption_label")}
              </FieldLabel>
              <GroupContent>
                <RotationConfig
                  translate={translate}
                  rotationValueInput={formData.DEFAULT_ROTATION_PERIOD_MESSAGES}
                  helperDescription={"resources.settings.fields.rotation_period_messages_setting_helper"}
                  onRotationChange={onRotationChange}
                />
              </GroupContent>
            </Grid>
            <Grid width="50%" margin="15px" textAlign="right">
                <Button
                    component="label"
                    variant="outlined"
                    startIcon={<ListIcon/>}
                    sx={{ marginTop: "10px" }}
                    onClick={openBlacklistIps}
                >
                    {translate("resources.settings.labels.list_ip_addresses")}
                </Button>
            </Grid>
          </Grid>;
        }}
      </FormDataConsumer>
    </FormTab>
  );
};

const StyledTextField = styled(TextField)({
  '&.MuiTextField-root': {
    marginBottom: '30px',
  },
});
