import { required, regex } from "react-admin";

export const IPV4PATTERN =
    // eslint-disable-next-line max-len
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|::1)$/;
export const IPV6PATTERN =
    // eslint-disable-next-line max-len
    /^(?:[0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$|^(?=(?:.*?[0-9a-fA-F]{1,4}:){2,})[0-9a-fA-F]{1,4}(?::[0-9a-fA-F]{1,4}){1,6}$|^::1$/;

export const validateDomains = [
  required(),
  regex(
    /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/,
    "resources.settings.validations.regex_domain"
  ),
];

export function isProduction() {
  return process.env.NODE_ENV?.toLowerCase() === "production";
}

export const formatDate = date => {
  const newData = new Date(date);
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  return newData.toLocaleDateString("en-US", options);
};

/**
 * Shorten string.
 * @param {string} str input string
 * @param {number} max characters
 * @param {string} sep optional customize the separator
 *
 * @returns {string}
 *
 * @example
 * console.log( truncate("123456789abcde") ); // 123...bcde (using built-in defaults)
 * console.log( truncate("123456789abcde", 8) ); // 12...cde (max of 8 characters)
 * console.log( truncate("123456789abcde", 12, "_") ); // 12345_9abcde (customize the separator)
 */
export function truncate(str, max, sep = '...') {
  // Default to 10 characters
  max = max || 10;

  var len = str.length;
  if (len > max) {
    // Default to elipsis
    sep = sep || "...";

    var seplen = sep.length;

    // If seperator is larger than character limit,
    // well then we don't want to just show the seperator,
    // so just show right hand side of the string.
    if (seplen > max) {
      return str.substr(len - max);
    }

    // Half the difference between max and string length.
    // Multiply negative because small minus big.
    // Must account for length of separator too.
    var n = -0.5 * (max - len - seplen);

    // This gives us the centerline.
    var center = len / 2;

    var front = str.substr(0, center - n);
    var back = str.substr(len - center + n); // without second arg, will automatically go to end of line.

    return front + sep + back;
  }

  return str;
}

/**
 * Check valid file type.
 * @param {string} fileExtension file extension
 * @param {Array.<string>} allowedExtension list allow extension
 *
 * @returns {boolean}
 *
 * @example
 * console.log( validateFileType('image/jpg', ["png", "jpg", "jpeg"]) ); // true
 */
export function validateFileType(fileExtension, allowedExtension) {
  var isValid = false;

  for (var index in allowedExtension) {
    if (fileExtension.includes(allowedExtension[index])) {
      isValid = true;
      break;
    }
  }

  return isValid;
}

/**
 * Check inValid format ip address.
 * @param {string} ipAddress file extension
 *
 * @returns {boolean}
 */
export function validateFormatIpAddress(ipAddress) {
  let isInValid = true;
  if (!ipAddress) {
    return isInValid;
  }
  isInValid = !IPV4PATTERN.test(ipAddress) && !IPV6PATTERN.test(ipAddress);
  return isInValid;
}

export function isMobile() {
  const mobileRegex = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i;
  const userAgent = navigator.userAgent.toLowerCase();
  return mobileRegex.test(userAgent);
}

export function sortAlphabetASC(arrayInput) {
  return arrayInput.sort((a, b) => {
      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
  });
}

export function sortKeys(unordered) {
  return sortAlphabetASC(Object.keys(unordered))
      .reduce((obj, key) => {
          obj[key] = unordered[key];
          return obj;
      }, {});
}

export function buildQueryString(data) {
  let params = [];
  Object.keys(data).forEach((k) => {
      params.push(`${k}=${encodeURIComponent(data[k])}`);
  });
  return params.join('&');
}

export function randomString(len, charSet) {
  charSet = charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var randomString = '';
  for (var i = 0; i < len; i++) {
      var randomPoz = Math.floor(Math.random() * charSet.length);
      randomString += charSet.substring(randomPoz,randomPoz+1);
  }
  return randomString;
}

export const getTimeByDays = (days) => {
  return days * 24 * 60 * 60 * 1000;
};

export const getDaysByTime = (time) => {
  return time / 24 / 60 / 60 / 1000;
};

export const getTimeByMinues = (minutes) => {
  return minutes * 60 * 1000;
};

export const getMinuesByTime = (time) => {
  return time / 60 / 1000;
};

export const getTimeByHours = (hours) => {
  return hours * 60 * 60 * 1000;
};

export const getHoursByTime = (time) => {
  return time / 60 / 60 / 1000;
};

export const getHoursBySeconds = (second) => {
  return second / 60 / 60;
};

export const getSecondsByHours = (hours) => {
  return hours * 60 * 60;
};

export const bytesToMegabytes = (bytes) => {
  console.log(bytes / (1024 * 1024))
  return bytes / (1024 * 1024);
};

export const megabytesToBytes = (megabytes) => {
  return megabytes * (1024 * 1024);
};

export const transformStringify = (originalString) => {
  return originalString?.replace(/(["\\])/g, '\\$1');
};

export const stringToArray = (text) => {
  return text?.split(',').map(item => item.trim());
};

export const validateArrayValuesInEnum = (valuesArray, enumObject) => {
  const validValues = Object.values(enumObject);
  return valuesArray.every(value => validValues.includes(value));
}
