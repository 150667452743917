import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import { generateRandomUser } from "../components/users";
import { EErrorCode } from "../enum/EErrorCode";
import { EUserThreePID } from "../enum/EThreePID";
import { HttpStatus } from "../enum/HttpStatus";
import { logger } from "../utils/logger";
import authProvider from "./authProvider";
import roleProvider from "./roleProvider";
import botProvider from "./botProvider";
import { getServerConfig } from "../helper/serverConfig";

// Adds the access token to all requests
export const jsonClient = (path, options = {}) => {
  const homeserver = getServerConfig("home_server_url");
  const url = `${homeserver}${path}`;
  const authorization = options.headers?.get("Authorization");
  const token = localStorage.getItem("access_token");
  if (token !== null) {
    options.user = {
      authenticated: true,
      token: authorization ?? `Bearer ${token}`,
    };
  }
  return fetchUtils.fetchJson(url, options).catch(error => {
    const { status, body } = error;
    if (
      token !== null &&
      ((status === HttpStatus.FORBIDDEN &&
        body?.errcode === EErrorCode.M_ACCESS_FORBIDDEN) ||
        status === HttpStatus.UNAUTHORIZED)
    ) {
      if (!options.skipLogout) {
        authProvider.logout();
      }
    }
    return Promise.reject(error);
  });
};

export const mxcUrlToHttp = (mxcUrl, options = {}) => {
  const defaultOptions = {
    width: 24,
    height: 24,
    method: "scale", // scale | crop
  };
  const query = new URLSearchParams({ ...defaultOptions, ...options });
  const homeserver = getServerConfig("home_server_url");
  const re = /^mxc:\/\/([^/]+)\/(\w+)/;
  const ret = re.exec(mxcUrl);
  if (ret == null) return null;
  const serverName = ret[1];
  const mediaId = ret[2];
  return `${homeserver}/_matrix/media/r0/thumbnail/${serverName}/${mediaId}?${query.toString()}`;
};

const resourceMap = {
  users: {
    path: "/_synapse/admin/v2/users",
    map: u => {
      // if (u.threepids && u.threepids.length) {
      //   logger.log(u);
      //   u.threepids = u.threepids.map(t => !t.address.include("@"));
      // }
      const thirdpidUsername =
        u.threepids && u.threepids.length
          ? u.threepids.filter(t => t.medium === EUserThreePID.username)
          : [];

      const thirdpidPhoneNumber =
        u.threepids && u.threepids.length
          ? u.threepids.filter(
              t =>
                t.medium === EUserThreePID.phone && !t.address.startsWith("@")
            )
          : [];

      const thirdpidUserEmail =
        u.threepids && u.threepids.length
          ? u.threepids.filter(t => t.medium === EUserThreePID.email)
          : [];

      const settingsPlatform = u.settings?.platforms;

      return {
        ...u,
        phonenumber: thirdpidPhoneNumber.length
          ? thirdpidPhoneNumber[0].address
          : "",
        username: thirdpidUsername.length
          ? thirdpidUsername[0].address.replace("@", "")
          : "",
        email: thirdpidUserEmail.length ? thirdpidUserEmail[0].address : "",
        id: u.name,
        avatar_src: mxcUrlToHttp(u.avatar_url),
        is_guest: !!u.is_guest,
        yubikey: !!u.yubikey,
        two_factor: !!u.two_factor,
        admin: !!u.admin,
        deactivated: !!u.deactivated,
        // need timestamp in milliseconds
        creation_ts_ms: u.creation_ts * 1000,
        settingsPlatform,
      };
    },
    data: "users",
    total: json => json.total,
    create: data => {
      // Default user create from import
      // if create user from manual
      if (!data.threepids || !data.threepids.length) {
        data.threepids = [];
      }
      const overwriteData = generateRandomUser();
      data.id = data.id || overwriteData.id;
      data.displayname = data.displayname.trim();
      if (data.email) {
        const email = data.email.trim();
        // data.id = email.replace('@', '_');
        data.threepids.push({
          medium: "email",
          address: email,
        });
      }
      if (data.phonenumber) {
        const phone = data.phonenumber.trim();
        // if (!data.id) {
        //   data.id = `ctalk_${phone}`;
        // }
        // data.displayname =
        //   data.displayname && data.displayname.trim() !== ""
        //     ? data.displayname.trim()
        //     : phone;
        data.threepids.push({
          medium: "msisdn",
          address: phone,
        });
      }

      return {
        // endpoint: `/_synapse/admin/v2/users/@${data.id}:${localStorage.getItem(
        //   "home_server"
        // )}`,
        endpoint: `/_synapse/admin/v2/users/${data.id}`,
        body: data,
        method: "POST",
      };
    },
    delete: params => ({
      endpoint: `/_synapse/admin/v1/deactivate/${params.id}`,
      body: { erase: true },
      method: "POST",
    }),
    update: data => {
      data.displayname = data.displayname.trim();
      data.threepids = [];
      if (data.email) {
        const email = data.email.trim();
        data.threepids.push({
          medium: "email",
          address: email,
        });
      }
      if (data.phonenumber) {
        const phone = data.phonenumber.trim();
        data.threepids.push({
          medium: "msisdn",
          address: phone,
        });
      }
      data.settings.platforms = data.settingsPlatform;
      delete data.settingsPlatform;
      return data;
    },
    import: data => {
      if (!data.threepids || !data.threepids.length) {
        data.threepids = [];
      }
      const overwriteData = generateRandomUser();
      data.id = data.id || overwriteData.id;
      data.displayname = data.displayname.trim();
      if (data.email) {
        const email = data.email.trim();
        data.threepids.push({
          medium: "email",
          address: email,
        });
      }
      if (data.phonenumber) {
        const phone = data.phonenumber.trim();
        data.threepids.push({
          medium: "msisdn",
          address: phone,
        });
      }

      return {
        endpoint: `/_synapse/admin/v2/users/import/${data.id}`,
        body: data,
        method: "POST",
      };
    },
    pathExport: "/_synapse/admin/v2/users/export",
    mapExport: u => {
      return {
        ...u,
        id: u.name,
        avatar_src: mxcUrlToHttp(u.avatar_url),
        is_guest: !!u.is_guest,
        yubikey: !!u.yubikey,
        two_factor: !!u.two_factor,
        admin: !!u.admin,
        deactivated: !!u.deactivated,
        creation_ts_ms: u.creation_ts * 1000,
      };
    },
  },
  rooms: {
    path: "/_synapse/admin/v1/rooms",
    map: r => ({
      ...r,
      id: r.room_id,
      alias: r.canonical_alias,
      members: r.joined_members,
      is_encrypted: !!r.encryption,
      federatable: !!r.federatable,
      public: !!r.public,
    }),
    data: "rooms",
    total: json => {
      return json.total_rooms;
    },
    delete: params => ({
      endpoint: `/_synapse/admin/v1/rooms/${params.id}`,
      body: { block: false },
    }),
    pathExport: "/_synapse/admin/v1/rooms/export",
    mapExport: r => ({
      ...r,
      id: r.room_id,
      alias: r.canonical_alias,
      members: r.joined_members,
      is_encrypted: !!r.encryption,
      federatable: !!r.federatable,
      public: !!r.public,
    }),
  },
  reports: {
    path: "/_synapse/admin/v1/event_reports",
    map: er => ({
      ...er,
      id: er.id,
    }),
    data: "event_reports",
    total: json => json.total,
  },
  devices: {
    map: d => ({
      ...d,
      id: d.device_id,
    }),
    data: "devices",
    total: json => {
      return json.total;
    },
    reference: id => ({
      endpoint: `/_synapse/admin/v2/users/${id}/devices`,
    }),
    delete: params => ({
      endpoint: `/_synapse/admin/v2/users/${params.user_id}/devices/${params.id}`,
    }),
  },
  connections: {
    path: "/_synapse/admin/v1/whois",
    map: c => ({
      ...c,
      id: c.user_id,
    }),
    data: "connections",
  },
  room_members: {
    map: m => ({
      id: m,
    }),
    reference: id => ({
      endpoint: `/_synapse/admin/v1/rooms/${id}/members`,
    }),
    data: "members",
    total: json => {
      return json.total;
    },
  },
  room_state: {
    map: rs => ({
      ...rs,
      id: rs.event_id,
    }),
    reference: id => ({
      endpoint: `/_synapse/admin/v1/rooms/${id}/state`,
    }),
    data: "state",
    total: json => {
      return json.state.length;
    },
  },
  pushers: {
    map: p => ({
      ...p,
      id: p.pushkey,
    }),
    reference: id => ({
      endpoint: `/_synapse/admin/v1/users/${id}/pushers`,
    }),
    data: "pushers",
    total: json => {
      return json.total;
    },
  },
  joined_rooms: {
    map: jr => ({
      id: jr,
    }),
    reference: id => ({
      endpoint: `/_synapse/admin/v1/users/${id}/joined_rooms`,
    }),
    data: "joined_rooms",
    total: json => {
      return json.total;
    },
  },
  users_media: {
    map: um => ({
      ...um,
      id: um.media_id,
    }),
    reference: id => ({
      endpoint: `/_synapse/admin/v1/users/${id}/media`,
    }),
    data: "media",
    total: json => {
      return json.total;
    },
    delete: params => ({
      endpoint: `/_synapse/admin/v1/media/${localStorage.getItem(
        "home_server"
      )}/${params.id}`,
    }),
  },
  delete_media: {
    delete: params => ({
      endpoint: `/_synapse/admin/v1/media/delete?before_ts=${params.before_ts}&size_gt=${params.size_gt}&keep_profiles=${params.keep_profiles}`,
      method: "POST",
    }),
  },
  protect_media: {
    map: pm => ({ id: pm.media_id }),
    create: params => ({
      endpoint: `/_synapse/admin/v1/media/protect/${params.media_id}`,
      method: "POST",
    }),
    delete: params => ({
      endpoint: `/_synapse/admin/v1/media/unprotect/${params.media_id}`,
      method: "POST",
    }),
  },
  quarantine_media: {
    map: qm => ({ id: qm.media_id }),
    create: params => ({
      endpoint: `/_synapse/admin/v1/media/quarantine/${localStorage.getItem(
        "home_server"
      )}/${params.media_id}`,
      method: "POST",
    }),
    delete: params => ({
      endpoint: `/_synapse/admin/v1/media/unquarantine/${localStorage.getItem(
        "home_server"
      )}/${params.media_id}`,
      method: "POST",
    }),
  },
  servernotices: {
    map: n => ({ id: n.event_id }),
    create: data => ({
      endpoint: "/_synapse/admin/v1/send_server_notice",
      body: {
        user_id: data.id,
        content: {
          msgtype: "m.text",
          body: data.body,
        },
      },
      method: "POST",
    }),
  },
  user_device_statistics: {
    path: "/_synapse/admin/v1/statistics/users/device",
    map: usms => ({
      ...usms,
      id: usms.user_id,
    }),
    data: "users",
    total: json => {
      return json.total;
    },
  },
  user_media_statistics: {
    path: "/_synapse/admin/v1/statistics/users/media",
    map: usms => ({
      ...usms,
      id: usms.user_id,
    }),
    data: "users",
    total: json => {
      return json.total;
    },
  },
  forward_extremities: {
    map: fe => ({
      ...fe,
      id: fe.event_id,
    }),
    reference: id => ({
      endpoint: `/_synapse/admin/v1/rooms/${id}/forward_extremities`,
    }),
    data: "results",
    total: json => {
      return json.count;
    },
    delete: params => ({
      endpoint: `/_synapse/admin/v1/rooms/${params.id}/forward_extremities`,
    }),
  },
  room_directory: {
    path: "/_matrix/client/r0/publicRooms",
    map: rd => ({
      ...rd,
      id: rd.room_id,
      public: !!rd.public,
      guest_access: !!rd.guest_access,
      avatar_src: mxcUrlToHttp(rd.avatar_url),
    }),
    data: "chunk",
    total: json => {
      return json.total_room_count_estimate;
    },
    create: params => ({
      endpoint: `/_matrix/client/r0/directory/list/room/${params.id}`,
      body: { visibility: "public" },
      method: "PUT",
    }),
    delete: params => ({
      endpoint: `/_matrix/client/r0/directory/list/room/${params.id}`,
      body: { visibility: "private" },
      method: "PUT",
    }),
  },
  registration_tokens: {
    path: "/_synapse/admin/v1/registration_tokens",
    map: rt => ({
      ...rt,
      id: rt.token,
    }),
    data: "registration_tokens",
    total: json => {
      return json.registration_tokens.length;
    },
    create: params => ({
      endpoint: "/_synapse/admin/v1/registration_tokens/new",
      body: params,
      method: "POST",
    }),
    delete: params => ({
      endpoint: `/_synapse/admin/v1/registration_tokens/${params.id}`,
    }),
  },
  roles: {
    map: (u, index, from) => {
      return {
        ...u,
        no: index + from + 1,
        id: u.roleId,
        name: u.name,
        createdAt: u.createdAt,
        updatedAt: u.updatedAt,
      };
    },
    data: "roles",
    total: json => json.total,
  },
  bots: {
    map: (u, index, from) => {
      return {
        ...u,
        no: index + from + 1,
        id: u.id,
        botId: u.botId,
        name: u.name,
        isEnabled: u.isEnabled === 1,
        createdAt: u.createdAt,
        description: u.description,
        avatarUrl: u.avatarUrl,
        avatarSrc: mxcUrlToHttp(u.avatarUrl, {
          width: 40,
          height: 40,
          method: "crop",
        }),
        tokens: u.tokens,
      };
    },
    data: "bots",
    total: json => json.total,
    delete: params => ({
      endpoint: `/bots/${params.id}`,
    }),
  },
};

function filterNullValues(key, value) {
  // Filtering out null properties
  if (value === null) {
    return undefined;
  }
  return value;
}

function getSearchOrder(order) {
  if (order === "DESC") {
    return "b";
  } else {
    return "f";
  }
}

async function handleGetList(resource, params) {
  const {
    user_id,
    name,
    guests,
    admin,
    deactivated,
    search_term,
    valid,
    yubikey,
    two_factor,
  } = params.filter;
  const { page, perPage } = params.pagination;
  const { field, order } = params.sort;
  const from = (page - 1) * perPage;
  const query = {
    from: from,
    limit: perPage,
    user_id: user_id,
    search_term: search_term,
    name: name,
    admin: admin || undefined,
    guests: guests,
    deactivated: deactivated,
    yubikey: yubikey || undefined,
    two_factor: two_factor || undefined,
    valid: valid,
    order_by: field,
    dir: getSearchOrder(order),
  };

  const res = resourceMap[resource];

  const path = `${res.path}?${stringify(query)}`;

  return jsonClient(path).then(({ json }) => ({
    data: json[res.data].map(res.map),
    total: res.total(json, from, perPage),
  }));
}

const dataProvider = {
  getList: async (resource, params) => {
    logger.log("getList " + resource);

    switch (resource) {
      case "roles": // Resource custom
        return roleProvider.getList(resourceMap["roles"], params);
      case "bots": // Resource custom
        return botProvider.getList(resourceMap["bots"], params);
      default:
        return handleGetList(resource, params);
    }
  },

  getOne: (resource, params) => {
    logger.log("getOne " + resource);
    const res = resourceMap[resource];
    return jsonClient(`${res.path}/${params.id}`).then(({ json }) => ({
      data: res.map(json),
    }));
  },

  getMany: (resource, params) => {
    logger.log("getMany " + resource);

    const res = resourceMap[resource];

    return Promise.all(
      params.ids.map(id => jsonClient(`${res.path}/${id}`))
    ).then(responses => ({
      data: responses.map(({ json }) => res.map(json)),
      total: responses.length,
    }));
  },

  getManyReference: (resource, params) => {
    logger.log("getManyReference " + resource);
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const from = (page - 1) * perPage;
    const query = {
      from: from,
      limit: perPage,
      order_by: field,
      dir: getSearchOrder(order),
    };

    const res = resourceMap[resource];
    const ref = res["reference"](params.id);
    const path = `${ref.endpoint}?${stringify(query)}`;

    // noinspection JSUnusedLocalSymbols
    return jsonClient(path).then(({ headers, json }) => ({
      data: json[res.data].map(res.map),
      total: res.total(json, from, perPage),
    }));
  },

  update: (resource, params) => {
    logger.log("update " + resource);

    const res = resourceMap[resource];
    const updateData =
      resource === "users" ? res["update"](params.data) : params.data;
    return jsonClient(`${res.path}/${params.data.id}`, {
      method: "PUT",
      body: JSON.stringify(updateData, filterNullValues),
    }).then(({ json }) => ({
      data: res.map(json),
    }));
  },

  updateMany: (resource, params) => {
    logger.log("updateMany " + resource);

    const res = resourceMap[resource];

    return Promise.all(
      params.ids.map(id => jsonClient(`${res.path}/${id}`), {
        method: "PUT",
        body: JSON.stringify(params.data, filterNullValues),
      })
    ).then(responses => ({
      data: responses.map(({ json }) => json),
    }));
  },

  create: (resource, params) => {
    logger.log("create " + resource);

    const res = resourceMap[resource];
    if (!("create" in res)) return Promise.reject();

    const create = res["create"](params.data);
    return jsonClient(create.endpoint, {
      method: create.method,
      body: JSON.stringify(create.body, filterNullValues),
    }).then(({ json }) => ({
      data: res.map(json),
    }));
  },

  createMany: (resource, params) => {
    logger.log("createMany " + resource);

    const res = resourceMap[resource];
    if (!("create" in res)) return Promise.reject();

    return Promise.all(
      params.ids.map(id => {
        params.data.id = id;
        const cre = res["create"](params.data);
        return jsonClient(cre.endpoint, {
          method: cre.method,
          body: JSON.stringify(cre.body, filterNullValues),
        });
      })
    ).then(responses => ({
      data: responses.map(({ json }) => json),
    }));
  },

  delete: (resource, params) => {
    logger.log("delete " + resource);

    const res = resourceMap[resource];

    if ("delete" in res) {
      const del = res["delete"](params);
      return jsonClient(del.endpoint, {
        method: "method" in del ? del.method : "DELETE",
        body: "body" in del ? JSON.stringify(del.body) : null,
      }).then(({ json }) => ({
        data: json,
      }));
    } else {
      return jsonClient(`${res.path}/${params.id}`, {
        method: "DELETE",
        body: JSON.stringify(params.data, filterNullValues),
      }).then(({ json }) => ({
        data: json,
      }));
    }
  },

  deleteMany: (resource, params) => {
    logger.log("deleteMany " + resource);

    const res = resourceMap[resource];

    if ("delete" in res) {
      return Promise.all(
        params.ids.map(id => {
          const del = res["delete"]({ ...params, id: id });
          return jsonClient(del.endpoint, {
            method: "method" in del ? del.method : "DELETE",
            body: "body" in del ? JSON.stringify(del.body) : null,
          });
        })
      ).then(responses => ({
        data: responses.map(({ json }) => json),
      }));
    } else {
      return Promise.all(
        params.ids.map(id =>
          jsonClient(`${res.path}/${id}`, {
            method: "DELETE",
            body: JSON.stringify(params.data, filterNullValues),
          })
        )
      ).then(responses => ({
        data: responses.map(({ json }) => json),
      }));
    }
  },

  import: (resource, params) => {
    logger.log("import " + resource);

    const res = resourceMap[resource];
    if (!("import" in res)) return Promise.reject();

    const importData = res["import"](params.data);
    return jsonClient(importData.endpoint, {
      method: importData.method,
      body: JSON.stringify(importData.body, filterNullValues),
    }).then(({ json }) => ({
      data: res.map(json),
    }));
  },

  export: async (resource, params) => {
    logger.log("export " + resource);

    const {
      user_id,
      name,
      guests,
      admin,
      deactivated,
      search_term,
      valid,
      yubikey,
      two_factor,
    } = params.filter;
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const from = (page - 1) * perPage;
    const query = {
      from: from,
      limit: perPage,
      user_id: user_id,
      search_term: search_term,
      name: name,
      admin: admin || undefined,
      guests: guests,
      deactivated: deactivated,
      yubikey: yubikey || undefined,
      two_factor: two_factor || undefined,
      valid: valid,
      order_by: field,
      dir: getSearchOrder(order),
    };

    const res = resourceMap[resource];

    const url = `${res.pathExport}?${stringify(query)}`;

    return jsonClient(url).then(({ json }) => ({
      data: json[res.data].map(res.mapExport),
      total: res.total(json, from, perPage),
    }));
  },
};

export default dataProvider;
